import React, { useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import styled from "styled-components";
import dayjs from "dayjs";
import toast from "react-hot-toast";

const VaultItemAdvanced = ({
  nft,
  type,
  action,
  conn,
  claimAction,
}: {
  nft: any;
  type: string;
  action: any;
  conn: any;
  claimAction?: any;
}) => {
  const [dateStaked, setDateStaked] = useState(null);
  const [dateCooldown, setDateCooldown] = useState(null);
  const [estEarn, setEstEarn] = useState(0);
  // const [farmer, setFarmer] = useState(null);
  const image = nft?.json ? nft?.json?.image : nft?.image;
  const name = nft?.json ? nft?.json?.name : nft?.name;

  useEffect(() => {
    async function fetchTransactionDate() {
      try {
        if (type === "staked") {
          const sigs = await conn.getSignaturesForAddress(
            new PublicKey(nft?.farmer?.publicKey?.toString()),
            {
              limit: 1,
            }
          );

          const trans = await conn.getParsedTransaction(sigs[0].signature);
          console.log(trans);

          if (
            trans &&
            trans?.meta?.logMessages?.includes(
              "Program log: Instruction: Claim" ||
                "Program log: Instruction: Stake"
            )
          ) {
            setDateStaked(dayjs.unix(sigs[0]?.blockTime));
          } else {
            const newSigs = await conn.getSignaturesForAddress(
              new PublicKey(nft?.farmer?.publicKey?.toString()),
              {
                limit: 10,
              }
            );

            let newArray = [];

            for (var i = 0; i < newSigs.length; i++) {
              var value = newSigs[i];
              const trans = await conn.getParsedTransaction(value.signature);

              if (
                trans &&
                trans?.meta?.logMessages?.includes(
                  "Program log: Instruction: Claim" ||
                    "Program log: Instruction: Stake"
                )
              ) {
                newArray.push(trans);
              }
            }

            const data = newArray[0];

            if (data) {
              setDateStaked(dayjs.unix(data.blockTime));
            } else {
              setDateStaked(dayjs.unix(sigs[0]?.blockTime));
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchTransactionDate();
  }, [conn, nft?.farmer?.publicKey, nft.mint, type]);

  useEffect(() => {
    if (type === "staked") {
      setDateCooldown(
        dayjs.unix(nft?.farmer?.account?.minStakingEndsTs.toString())
      );
    }
  }, [nft?.farmer?.account?.minStakingEndsTs, type]);

  useEffect(() => {
    async function fetchTransaction() {
      try {
        if (type === "staked") {
          if (dateStaked) {
            let diffInCoinsBase = 0;
            const earningsBase = 35;

            const diffInSecBase = dayjs(new Date()).diff(dateStaked, "seconds");
            diffInCoinsBase = diffInSecBase * (earningsBase / 86400);
            setEstEarn(diffInCoinsBase);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    const interval = setInterval(() => fetchTransaction(), 1000);

    return () => {
      clearInterval(interval);
    };
  }, [conn, dateStaked, nft?.mint, type]);

  return (
    <VaultItemContainer>
      <img src={image} alt={name} />
      <VaultItemDetailsContainer>
        <p style={{ marginBottom: 10 }}>{name}</p>
        {type === "staked" && (
          <p
            style={{
              fontSize: 10,
              marginBottom: 12,
            }}
          >
            Total $OINK Earned: {estEarn.toFixed(3)}
          </p>
        )}
        <VaultItemButtonsContainer>
          {type === "staked" &&
            claimAction &&
            dateCooldown < dayjs(new Date()) && (
              <button className="button" onClick={() => claimAction(nft)}>
                Claim
              </button>
            )}
          <button
            className={
              type === "staked"
                ? dateCooldown > dayjs(new Date())
                  ? "button button-secondary"
                  : "button"
                : "button"
            }
            onClick={
              type === "staked"
                ? dateCooldown > dayjs(new Date())
                  ? () => toast.error(`Pig is still locked up.`)
                  : () => action(nft)
                : () => action(nft)
            }
          >
            {type === "staked" ? (
              dateCooldown > dayjs(new Date()) ? (
                <>Locked</>
              ) : (
                "Unstake"
              )
            ) : (
              "Stake"
            )}
          </button>
        </VaultItemButtonsContainer>
      </VaultItemDetailsContainer>
    </VaultItemContainer>
  );
};

export default VaultItemAdvanced;

const VaultItemContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  display: flex;
  flex-direction: column;
  max-width: 270px;
  overflow: hidden;

  img {
    height: 180px;
    object-fit: cover;

    @media only screen and (min-width: 700px) {
      height: 270px;
    }
  }
`;

const VaultItemDetailsContainer = styled.div`
  padding: 12px;

  p {
    color: #222;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin: 0;
    margin-bottom: 4px;
  }
`;

const VaultItemButtonsContainer = styled.div`
  display: flex;
  margin-left: -4px;
  margin-right: -4px;

  button {
    border-radius: 4px;
    box-shadow: none;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    height: 36px;
    justify-content: center;
    margin-left: 4px;
    margin-right: 4px;
    /* margin-top: 8px; */
    width: 100%;

    &:hover {
      /* background: #020b1c; */
    }
  }
`;
