import { PublicKey } from "@solana/web3.js";

require("@solana/wallet-adapter-react-ui/styles.css");

export * from "./gem-bank";
export * from "./gem-farm";
export * from "./gem-common";

export const GEM_BANK_PROG_ID = new PublicKey(
  "H4zbPJV8JEqhjq1SPsZPeg2M9Eby3VtUMuRCAvHmhRRX"
);
export const GEM_FARM_PROG_ID = new PublicKey(
  "Dp8bcStZSpR4EyKuzcAJUFjnZKfoNo9iYM93BAxtDLve"
);
