import { programs } from "@metaplex/js";
import { BN, Wallet } from "@project-serum/anchor";
import { SignerWalletAdapter } from "@solana/wallet-adapter-base";
import { Connection, PublicKey, Transaction } from "@solana/web3.js";
import {
  findFarmerPDA,
  findWhitelistProofPDA,
  GemBankClient,
  GemFarmClient,
} from "gem-sdk";
import axios from "axios";

import { IDL as GemBank } from "../gem-sdk/types/gem_bank";
import { IDL as GemFarm } from "../gem-sdk/types/gem_farm";
export const GEM_BANK_PROGRAM_ID = new PublicKey(
  "H4zbPJV8JEqhjq1SPsZPeg2M9Eby3VtUMuRCAvHmhRRX"
);
export const GEM_FARM_PROGRAM_ID = new PublicKey(
  "Dp8bcStZSpR4EyKuzcAJUFjnZKfoNo9iYM93BAxtDLve"
);

const creatorId = process.env.REACT_APP_CREATOR_ID;
const creatorIdTwo = process.env.REACT_APP_CREATOR_ID_TWO;

const farmId = new PublicKey(process.env.REACT_APP_FARM_ID);

const {
  metadata: { Metadata },
} = programs;

// TODO This represents an array with the NFTs currently staked
// const currentStakedNFTs = [];
// TODO require the correct bank idl (explained in documentation)
const bankIdl = GemBank;
// TODO require the correct farm idl (explained in documentation)
const farmIdl = GemFarm;

export const initGemBank = (conn: Connection, wallet: SignerWalletAdapter) => {
  return new GemBankClient(
    conn,
    wallet as unknown as Wallet,
    bankIdl as any,
    GEM_BANK_PROGRAM_ID
  );
};

export const initGemFarm = (conn: Connection, wallet: SignerWalletAdapter) => {
  return new GemFarmClient(
    conn,
    wallet as unknown as Wallet,
    farmIdl as any,
    GEM_FARM_PROGRAM_ID,
    bankIdl as any,
    GEM_BANK_PROGRAM_ID
  );
};

export const stakeNft = async (
  connection,
  wallet,
  mint: PublicKey,
  associatedAddress: PublicKey,
  creator: PublicKey,
  publicKey: PublicKey,
  sendTransaction: Function,
  confirm: boolean = true
) => {
  const gf = await initGemFarm(
    connection,
    wallet!.adapter as SignerWalletAdapter
  );

  const gb = await initGemBank(
    connection,
    wallet!.adapter as SignerWalletAdapter
  );

  const txs = new Transaction();
  const farmer = await fetchFarmer(
    connection,
    wallet!.adapter as SignerWalletAdapter,
    publicKey!,
    mint
  );

  const farm = await fetchFarm(
    connection,
    wallet!.adapter as SignerWalletAdapter
  );

  let farmerVault: PublicKey;
  if (farmer === null) {
    // Initializes the farmer if it doesn't exist
    const { tx: createFarmerIx, vault } = await gf!.initFarmer(
      farmId,
      publicKey!,
      publicKey!,
      mint
    );
    txs.add(createFarmerIx);

    farmerVault = vault;
  } else {
    farmerVault = farmer.farmerAcc.vault;
  }

  const [mintProof] = await findWhitelistProofPDA(farm.bank, mint);

  const [creatorProof] = await findWhitelistProofPDA(farm.bank, creator);

  const metadata = await programs.metadata.Metadata.getPDA(mint);

  if (publicKey) {
    if (farmer !== null && farmer.farmerState === "staked") {
      const { tx: txDepositAndStake } = await gf!.flashDeposit(
        farmId,
        publicKey!,
        new BN(1),
        mint,
        associatedAddress,
        mintProof,
        metadata,
        creatorProof
      );

      txs.add(txDepositAndStake);
    } else {
      const { tx: txDepositIx } = await gb.depositGem(
        farm.bank,
        farmerVault,
        publicKey!,
        new BN(1),
        mint,
        associatedAddress,
        mintProof,
        metadata,
        creatorProof
      );

      txs.add(txDepositIx);

      const { tx: txStakeIx } = await gf!.stake(farmId, publicKey!, mint);
      txs.add(txStakeIx);
    }
  }

  try {
    let blockhashObj = await connection.getRecentBlockhash();
    txs.recentBlockhash = blockhashObj.blockhash;
    txs.feePayer = publicKey!;

    const txid = await sendTransaction(txs, connection);
    // if (!confirm) return txid;
    await connection.confirmTransaction(txid);
  } catch (e) {
    // setLoadingNft(false);
  }

  // refreshDatas();
};

const getFarmer = async (
  mint: PublicKey,
  publicKey: PublicKey,
  allFarmersFromWallet: any
) => {
  if (!publicKey) return;

  const [farmerPDA] = await findFarmerPDA(farmId, publicKey, mint);
  return allFarmersFromWallet.find(
    (x) => x.publicKey.toBase58() === farmerPDA.toBase58()
  );
};

/**
 * Unstakes a NFT
 * @param farmId - the public key of the farm
 * @param mint   - the mint public key of the nft
 */
export const unstakeNft = async (
  connection,
  wallet,
  mint: PublicKey,
  publicKey: PublicKey,
  sendTransaction: Function,
  allFarmersFromWallet,
  confirm: boolean = true
) => {
  const gf = await initGemFarm(
    connection,
    wallet!.adapter as SignerWalletAdapter
  );
  const gb = await initGemBank(
    connection,
    wallet!.adapter as SignerWalletAdapter
  );
  const farmAcc = await fetchFarm(
    connection,
    wallet!.adapter as SignerWalletAdapter
  );

  const farmer = await getFarmer(mint, publicKey, allFarmersFromWallet);

  // There's two calls to unstake, the first "unstakes" it
  const { tx: txUnstake } = await gf!.unstake(farmId, publicKey!, mint);
  // Then, the second ends the cooldown period
  const { tx: txCooldown } = await gf!.unstake(farmId, publicKey!, mint);
  // Then and only then we can withdraw the gem
  const { tx: txWithdraw } = await gb!.withdrawGem(
    farmAcc.bank,
    farmer!.account.vault,
    publicKey!,
    new BN(1),
    mint,
    publicKey!
  );

  const { tx: txClaim } = await gf.claim(
    new PublicKey(farmId),
    publicKey!,
    new PublicKey(farmAcc.rewardA.rewardMint!),
    new PublicKey(farmAcc.rewardB.rewardMint!),
    mint
  );

  const txs = new Transaction()
    .add(txUnstake)
    .add(txCooldown)
    .add(txWithdraw)
    .add(txClaim);

  let blockhashObj = await connection.getRecentBlockhash();
  txs.recentBlockhash = blockhashObj.blockhash;
  txs.feePayer = publicKey!;

  try {
    const txid = await sendTransaction(txs, connection);
    // if (!confirm) return txid;
    await connection.confirmTransaction(txid);
  } catch (e) {
    // setLoadingNft(false);
  }

  // refreshDatas();
};

export const claimNft = async (
  connection,
  wallet,
  publicKey: PublicKey,
  mint: PublicKey,
  sendTransaction: Function,
  confirm: boolean = true
) => {
  const gf = await initGemFarm(
    connection,
    wallet!.adapter as SignerWalletAdapter
  );

  const farmAcc = await fetchFarm(
    connection,
    wallet!.adapter as SignerWalletAdapter
  );
  if (farmAcc === null) return;

  const { tx: txClaim } = await gf.claim(
    new PublicKey(farmId),
    publicKey!,
    new PublicKey(farmAcc.rewardA.rewardMint!),
    new PublicKey(farmAcc.rewardB.rewardMint!),
    mint
  );

  const txs = new Transaction().add(txClaim);
  let blockhashObj = await connection.getRecentBlockhash();
  txs.recentBlockhash = blockhashObj.blockhash;
  txs.feePayer = publicKey!;

  try {
    const txid = await sendTransaction(txs, connection);
    // if (!confirm) return txid;
    await connection.confirmTransaction(txid);
  } catch (e) {
    // setLoadingInfos(false);÷
  }
  // refreshDatas();
};

export const fetchFarmer = async (
  connection: Connection,
  walletAdapter: SignerWalletAdapter,
  farmer: PublicKey,
  mint: PublicKey
): Promise<any> => {
  const gf = await initGemFarm(connection, walletAdapter);

  const [farmerPDA] = await findFarmerPDA(farmId, farmer, mint);

  try {
    const farmerIdentity = farmer.toBase58();
    const farmerAcc = await gf!.fetchFarmerAcc(farmerPDA);
    const farmerState = gf!.parseFarmerState(farmerAcc);

    return { farmerIdentity, farmerAcc, farmerState };
  } catch (e) {
    console.log({ e });
    return null;
  }
};

export const fetchFarm = async (
  connection: Connection,
  walletAdapter: SignerWalletAdapter
): Promise<any> => {
  const gf = await initGemFarm(connection, walletAdapter);
  return await gf!.fetchFarmAcc(farmId);
};

export async function populateVaultNFTs(
  connection: Connection,
  walletAdapter: SignerWalletAdapter,
  farmer
) {
  let currentVaultNFTs = [];

  let gb = await initGemBank(connection, walletAdapter);

  const vault = farmer.account.vault;

  let foundGDRs = await gb.fetchAllGdrPDAs(vault);
  if (foundGDRs && foundGDRs.length) {
    let mints = foundGDRs.map((gdr) => {
      return { mint: gdr.account.gemMint };
    });
    currentVaultNFTs = await getNFTMetadataForMany(mints, connection);
    let collator = new Intl.Collator(undefined, { numeric: true });
    currentVaultNFTs.sort((a, b) =>
      collator.compare(a.externalMetadata.name, b.externalMetadata.name)
    );
    return currentVaultNFTs;
  }
}

export async function getNFTMetadataForMany(tokens, conn) {
  const promises = [];
  // let returnedNfts = []
  tokens.forEach((t) => promises.push(getNFTMetadata(t.mint, conn, t.pubkey)));
  const nfts = (await Promise.all(promises)).filter((n) => !!n);

  const filteredNfts = nfts?.filter((nft) => {
    if (
      nft?.onchainMetadata?.data?.creators &&
      nft?.onchainMetadata?.data?.creators[0]?.verified &&
      (nft?.onchainMetadata?.data?.creators[0]?.address === creatorId ||
        nft?.onchainMetadata?.data?.creators[0]?.address === creatorIdTwo)
    ) {
      return nft;
    } else {
      return null;
    }
  });

  return filteredNfts;
}

async function getNFTMetadata(mint, conn, pubkey) {
  try {
    const metadataPDA = await Metadata.getPDA(mint);
    const onchainMetadata = (await Metadata.load(conn, metadataPDA)).data;
    const externalMetadata = (await axios.get(onchainMetadata.data.uri)).data;
    return {
      pubkey: pubkey ? new PublicKey(pubkey) : undefined,
      mint: new PublicKey(mint),
      onchainMetadata,
      externalMetadata,
    };
  } catch (e) {
    // console.log(`failed to pull metadata for token ${mint}`);
  }
}

export async function getTokensByOwner(conn, owner) {
  const walletNfts = await Metadata.findDataByOwner(conn, owner);

  const nfts = [];
  for (let nft of walletNfts)
    if (
      nft?.data?.creators &&
      nft?.data?.creators[0]?.verified &&
      (nft?.data?.creators[0]?.address === creatorId ||
        nft?.data?.creators[0]?.address === creatorIdTwo)
    )
      nfts.push({
        mint: new PublicKey(nft.mint),
        data: nft.data,
        json: await fetch(nft.data.uri).then((e) => e.json()),
      });

  let collator = new Intl.Collator(undefined, { numeric: true });
  nfts.sort((a, b) => collator.compare(a.data.name, b.data.name));

  return nfts;
}

export async function getNFTsByOwner(conn, owner) {
  const tokens = await getTokensByOwner(conn, owner);

  return tokens;
}
