import { useEffect, useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { PublicKey } from "@solana/web3.js";
import {
  claimNft,
  fetchFarm,
  getNFTsByOwner,
  initGemFarm,
  populateVaultNFTs,
  stakeNft,
  unstakeNft,
} from "helpers/functions";
import { SignerWalletAdapter } from "@solana/wallet-adapter-base";
import styled from "styled-components";
import Loading from "react-loading-spinkit";

import Logo from "../images/logo.png";
import Background from "../images/background.jpg";
import {
  Token,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { Container, ProgressBar } from "react-bootstrap";
import VaultItemAdvanced from "components/VaultItemAdvanced";
import { ProgramAccount } from "@project-serum/anchor";

const Home = ({ loading, setLoading }) => {
  const { connection } = useConnection();
  const { wallet, publicKey, sendTransaction } = useWallet();

  const [allFarmersFromWallet, setAllFarmersFromWallet] = useState(
    new Array<ProgramAccount>()
  );

  // const [rewardValue, setRewardValue] = useState(0);

  const [farm, setFarm] = useState(null);
  const [tokenAmount, setTokenAmount] = useState(null);

  const [stakedNfts, setStakedNfts] = useState(null);
  const [unstakedNfts, setUnstakedNfts] = useState(null);

  useEffect(() => {
    async function fetchFarmFunc() {
      // grab farm on load
      if (connection) {
        const farm = await fetchFarm(connection, null);
        setFarm(farm);
      }
    }

    fetchFarmFunc();
  }, [connection, publicKey, wallet]);

  useEffect(() => {
    if (publicKey!) {
      refreshAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  async function refreshAll() {
    await fetchAllFarmers();
    await getUnstakedNfts();
    await getStakedNfts();
    setLoading(false);
  }

  // async function getStakedNfts() {
  //   // const vaultNfts = await populateVaultNFTs(
  //   //   connection,
  //   //   wallet.adapter as SignerWalletAdapter,
  //   //   publicKey
  //   // );
  //   // await setStakedNfts(vaultNfts);
  // }

  async function getUnstakedNfts() {
    const walletNfts = await getNFTsByOwner(connection, publicKey);
    await setUnstakedNfts(walletNfts);
  }

  const claimAction = async (nft) => {
    await claimNft(connection, wallet, publicKey, nft.mint, sendTransaction);
    await refreshAll();
    refreshAll();
  };

  const stakeAction = async (nft) => {
    setLoading(true);
    const creator = new PublicKey(nft.data.creators[0].address);

    const associatedAddress = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      nft.mint,
      publicKey
    );

    await stakeNft(
      connection,
      wallet,
      nft.mint,
      associatedAddress,
      creator,
      publicKey,
      sendTransaction
    );

    await refreshAll();
    refreshAll();
  };

  const unstakeAction = async (nft) => {
    setLoading(true);
    await unstakeNft(
      connection,
      wallet,
      nft.mint,
      publicKey,
      sendTransaction,
      allFarmersFromWallet
    );

    await refreshAll();
    refreshAll();
  };

  const fetchAllFarmers = async () => {
    if (!publicKey) return;

    const gf = await initGemFarm(
      connection,
      wallet!.adapter as SignerWalletAdapter
    );

    setAllFarmersFromWallet(
      await gf.fetchAllFarmerPDAs(
        new PublicKey(process.env.REACT_APP_FARM_ID),
        publicKey
      )
    );
  };

  const getStakedNfts = async () => {
    if (publicKey) {
      const gf = await initGemFarm(
        connection,
        wallet!.adapter as SignerWalletAdapter
      );

      setTokenAmount(tokenAmount);

      const formatCurrentStakingNft = [];
      const farmers = await gf.fetchAllFarmerPDAs(
        new PublicKey(process.env.REACT_APP_FARM_ID),
        publicKey
      );
      setAllFarmersFromWallet(farmers);
      if (farmers !== null) {
        for (const farmer of farmers) {
          const currentStakingNft = await populateVaultNFTs(
            connection,
            wallet!.adapter as SignerWalletAdapter,
            farmer
          );

          if (currentStakingNft) {
            formatCurrentStakingNft.push(
              ...currentStakingNft?.map((e: any) => {
                return {
                  name: e.externalMetadata.name,
                  pubkey: e.pubkey,
                  mint: e.mint,
                  image: e.externalMetadata.image,
                  isStaked: true,
                  farmer: farmer,
                };
              })
            );
          }
        }
      }

      setStakedNfts(formatCurrentStakingNft);
    }
  };

  // staked percentage
  const percCalc = (farm?.gemsStaked?.toString() / 2500) * 100;
  const perc = percCalc.toFixed(2);

  if (!publicKey) {
    return (
      <PageWrapper>
        <PageContainer>
          <IntroContainer>
            <Container>
              <img
                src={Logo}
                alt={"KireiPigs"}
                // height={200}
                style={{
                  display: "flex",
                  margin: "0 auto 50px",
                  textAlign: "center",
                }}
              />
              <div className="nes-bg">
                <h2>Ready to stake?</h2>
                <p>
                  888 piggies on the Solana network. The Piggies will give you
                  the chance to Unlock the whole Pig Sty and all our Future
                  Plans and Earn our Utility Tokens $OOINK and $MUD.
                </p>
              </div>
            </Container>
            <StakingButtonsContainer>
              <WalletMultiButton />
            </StakingButtonsContainer>
          </IntroContainer>
          <Container>
            <h3 style={{ marginTop: 50, textAlign: "right" }}>
              {perc}% Piggies Staked
            </h3>
            <ProgressBar now={Number(perc)} animated />
          </Container>
        </PageContainer>
      </PageWrapper>
    );
  } else {
    return (
      <>
        {loading && (
          <div
            style={{
              background: "rgba(39,39,39,0.75)",
              height: "100vh",
              position: "fixed",
              width: "100vw",
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              zIndex: 10,
            }}
          >
            <Loading color={"#ffffff"} show={true} />
          </div>
        )}
        <PageWrapper>
          <WalletContainer>
            <WalletMultiButton />
          </WalletContainer>
          <PageContainer>
            <IntroContainer>
              <Container>
                <img
                  src={Logo}
                  alt={"Kirei"}
                  // height={200}
                  style={{
                    display: "flex",
                    margin: "0 auto 50px",
                    textAlign: "center",
                  }}
                />
                <div className="nes-bg">
                  <h2>Ready to stake?</h2>
                  <p>
                    888 piggies on the Solana network. The Piggies will give you
                    the chance to Unlock the whole Pig Sty and all our Future
                    Plans and Earn our Utility Tokens $OOINK and $MUD.
                  </p>
                  {/* <StakingButtonsContainer>
                    <button
                      className="button"
                      onClick={() => claim()}
                      disabled={!farmer}
                    >
                      Claim $OINK
                    </button>
                  </StakingButtonsContainer> */}
                </div>
              </Container>
            </IntroContainer>
            <Container>
              <h3 style={{ marginTop: 50, textAlign: "right" }}>
                {perc}% Piggies Staked
              </h3>
              <ProgressBar now={Number(perc)} animated />
              <VaultsContainer>
                {stakedNfts?.length > 0 && (
                  <VaultWrapper>
                    <h2>Your Staked Piggies ({stakedNfts?.length ?? 0})</h2>
                    <VaultContainer>
                      {stakedNfts?.map((nft) => {
                        return (
                          <VaultItemAdvanced
                            conn={connection}
                            key={nft?.mint?.toString()}
                            nft={nft}
                            type={"staked"}
                            action={() => unstakeAction(nft)}
                            claimAction={() => claimAction(nft)}
                          />
                        );
                      })}
                    </VaultContainer>
                  </VaultWrapper>
                )}
                {/* <button
                    className="button button-secondary"
                    style={{ display: "inline", width: 220, marginBottom: 40 }}
                    onClick={() => stakeAllAction()}
                  >
                    Stake All
                  </button> */}
                {unstakedNfts?.length > 0 && (
                  <VaultWrapper>
                    <h2>Your Unstaked Piggies, lets earn some $OINK</h2>{" "}
                    <VaultContainer>
                      {unstakedNfts?.map((nft) => (
                        <VaultItemAdvanced
                          conn={null}
                          key={nft?.mint?.toString()}
                          nft={nft}
                          type={"unstaked"}
                          action={() => stakeAction(nft)}
                        />
                      ))}
                    </VaultContainer>
                  </VaultWrapper>
                )}
              </VaultsContainer>
            </Container>
          </PageContainer>
        </PageWrapper>
      </>
    );
  }
};

export default Home;

const PageWrapper = styled.div`
  margin-bottom: auto;
`;
const PageContainer = styled.div`
  .progress-bar {
    background-color: #0b843c !important;
  }

  h3 {
    font-size: 18px;
  }
`;
const WalletContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`;
const IntroContainer = styled.div`
  background: url(${Background}) #161b29;
  background-position: center;
  background-size: cover;
  color: #fff;
  padding: 40px 0;
  text-align: center;
  /* width: 100%; */

  .nes-bg {
    border-image-slice: 2;
    border-image-width: 2;
    border-image-repeat: stretch;
    border-image-source: url(data:image/svg+xml; utf8; base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiID8+PHN2ZyB2ZXJzaW9uPSIxLjEiIHdpZHRoPSI1IiBoZWlnaHQ9IjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIgMSBoMSB2MSBoLTEgeiBNMSAyIGgxIHYxIGgtMSB6IE0zIDIgaDEgdjEgaC0xIHogTTIgMyBoMSB2MSBoLTEgeiIgZmlsbD0icmdiKDMzLDM3LDQxKSIgLz48L3N2Zz4=);
    border-image-outset: 2;
    position: relative;
    display: block;
    padding: 20px 8px;
    margin: 0 auto;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #fff;
    background-color: #0b843c;
    border-style: solid;
    border-width: 4px;
    max-width: 900px;
    width: fit-content;
  }

  @media only screen and (max-width: 768px) {
    /* padding: 20px; */
  }

  button {
    display: inline-flex;
  }

  p {
    font-size: 15px;
    margin: 0 auto;
    max-width: 600px;
  }
`;

const VaultsContainer = styled.div`
  /* display: flex; */
  /* flex-flow: row wrap; */
  /* justify-content: center; */
  margin: 50px auto;

  @media only screen and (min-width: 768px) {
    /* display: grid; */
    /* grid-gap: 8px; */
    /* grid-template-columns: repeat(2, 1fr); */
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 20px;
  }
`;

const VaultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  h2 {
    font-size: 16px;
    text-align: left;
  }

  @media only screen and (max-width: 768px) {
    &:first-child {
      order: 1;
    }

    &:last-child {
      order: 0;
    }
  }
`;

const VaultContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, minmax(0px, 1fr));

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }
  /* display: flex; */
  /* flex-flow: row wrap; */
  /* flex-direction: column; */
`;

const StakingButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px auto 0;

  .button {
    background: #fff;
    color: #0b843c;
    margin-left: 8px;
    margin-right: 8px;

    &:hover {
      background: #fff;
      color: #0b843c;
    }

    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
`;
